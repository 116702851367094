<template>
  <section class="dashboard">
    <div class="page-header">
      <h3 class="page-title">
      <span class="page-title-icon bg-gradient-primary text-white mr-2">
        <i class="mdi mdi-home"></i>
      </span> Dashboard </h3>
      <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
          </li>
        </ul>
      </nav>
    </div>
    <div class="row">
      <div class="col-md-12">
        <carousel>
          <slide v-for="(item, index) in banner"  :key="index">
            <!-- <div class="col-lg-12 justify-content-md-center stretch-card grid-margin" style="cursor: pointer;"> -->
              <!-- <div class="row justify-content-md-center"> -->
                <template v-if="item.image != null">
                  <div class="bg-white card-img-holder p-3">
                    <img :src="item.image" class="justify-content-md-center col-lg-12" alt="circle-image"/>
                  </div>                  
                </template>
              <!-- </div> -->
            <!-- </div> -->
            <!-- {{ item.image }} -->
          </slide>
          <!-- <slide>
            Slide 2 Content
          </slide> -->
        </carousel>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-success card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <i class="mdi mdi-chart-line mdi-24px float-right"></i>
            <h4 class="font-weight-normal mb-3">Pemasukan Minggu ini
            </h4>
            <h2 class="mb-5">Rp. {{ totalWeekIncome }}</h2>
            <!-- <h6 class="card-text">Increased by 60%</h6> -->
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-success card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <i class="mdi mdi-chart-line mdi-24px float-right"></i>
            <h4 class="font-weight-normal mb-3">Order Minggu ini</h4>
            <h2 class="mb-5">{{ totalWeekOrder }}</h2>
            <!-- <h6 class="card-text">Increased by 60%</h6> -->
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-danger card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <i class="mdi mdi-diamond mdi-24px float-right"></i>
            <h4 class="font-weight-normal mb-3">Complain Minggu ini</h4>
            <h2 class="mb-5">{{ this.weekComplain }}</h2>
            <!-- <h6 class="card-text">Increased by 5%</h6> -->
          </div>  
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="clearfix">
              <h4 class="card-title float-left">Order Antar Barang dan Gudang</h4>
              <h4 class="card-title float-right">2023</h4>              
              <div id="visit-sale-chart-legend" class="rounded-legend legend-horizontal legend-top-right float-right">
                <ul>
                  <li>
                    <span class="legend-dots bg-gradient-primary"></span>Antar Barang
                  </li> 
                  <li>
                      <span class="legend-dots bg-gradient-danger"></span>Gudang
                  </li> 
                  <!-- <li>
                        <span class="legend-dots bg-gradient-info"></span>Lainnya
                  </li> -->
                </ul>
              </div>
            </div>
              <visitAndSalesStatitics class='mt-5' :height='170'></visitAndSalesStatitics>
          </div>
        </div>
      </div>
      <div class="col-md-5 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Performa Kurir Ambil dan Antar</h4>
            <trafficSourceChart :height='200'></trafficSourceChart>
            <div id="traffic-chart-legend" class="rounded-legend legend-vertical legend-bottom-left pt-4">
              <ul>
                <li>
                  <span class="legend-dots bg-gradient-info"></span>Puas
                  <span class="float-right">0%</span>
                </li> 
                <li>
                  <span class="legend-dots bg-gradient-success"></span>Cukup Puas
                  <span class="float-right">0%</span>
                </li> 
                <li>
                  <span class="legend-dots bg-gradient-danger"></span>Tidak Puas
                  <span class="float-right">0%</span>
                  </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Complain Ticket list</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> Pengguna </th>
                    <th> Subject </th>
                    <th> Tanggal Complain </th>                    
                    <th> Status </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in allComplain" :key="index" class="list-selected-hover" @click="toHandlingComplainUser(item)">
                    <td>
                      <template v-if="item.photo_profile != null">
                        <img :src="item.photo_profile" class="mr-2" alt="image" style="width: 36px;height: 36px;border-radius: 100%;">
                      </template>
                      <template v-else>
                        <img src="../../assets/images/faces/face1.jpg" class="mr-2" alt="image" style="width: 36px;height: 36px;border-radius: 100%;">
                      </template>
                      {{ item.fullname }}
                    </td>
                    <td> {{ item.subject }} </td>
                    <td> Dec 5, 2017 </td>
                    <td>
                      <p :class="`badge ${item.colorBadge}`">{{ item.status }}</p>
                    </td>                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>      

      <div class="col-4 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Chat User</h4>
            <div class="todo-list">
              <form @submit.prevent="submitTodo">
                <div class="add-items d-lg-flex">
                  <!-- <input type="text" class="form-control todo-list-input" placeholder="What do you need to do today?">
                  <button class="add btn btn-gradient-primary font-weight-bold todo-list-add-btn ml-0 ml-lg-2 mt-2 mt-lg-0" id="add-task">Add</button> -->
                </div>
              </form>
              <div class="list-wrapper">
                <ul class="d-flex flex-column-reverse todo-list">
                  <template v-if="allUnreadUser.length != 0">
                    <li v-for="(item, index) in allUnreadUser" :key="index" class="ml-0 list-selected-hover" @click="toChatUser(item)">
                      <template v-if="item.photo_profile != null">
                        <img :src="item.photo_profile" class="col-lg-3 mr-2 pull-left" alt="image" style="width: 36px;height: 36px;border-radius: 100%;">
                      </template>
                      <template v-else>
                        <img src="../../assets/images/faces/face1.jpg" class="col-lg-3 mr-2 pull-left" alt="image" style="width: 36px;height: 36px;border-radius: 100%;">
                      </template>                                                                
                      <h6 class="col-lg-6 pull-left ml-3 pt-2">{{item.shortname}}</h6>                      
                      <!-- <h5 class="col-lg-2 ml-3 count">{{item.total}}</h5> -->
                      <p :class="`badge badge-warning`">{{ item.total }}</p>                      
                    </li>                                    
                  </template>                  
                  <template v-else>
                    <h6 class="text-center">Tidak ada chat saat ini</h6>
                  </template>
                </ul>
                <b-button
                  class="col-lg-12 mt-3"
                  variant="primary"
                  href="javascript:void(0)"
                  @click="toChatUser('-')">Lihat Semua
                </b-button>                
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
    <!-- <div class="row">
      <div class="col-lg-5 grid-margin stretch-card">
        <div class="card">
          <date-picker v-model= "time1" valueType= "format" inline></date-picker>
        </div>
      </div>
      <div class="col-lg-7 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Recent Updates</h4>
            <div class="d-flex">
              <div class="d-flex align-items-center mr-4 text-muted font-weight-light">
                <i class="mdi mdi-account-outline icon-sm mr-2"></i>
                <span>jack Menqu</span>
              </div>
              <div class="d-flex align-items-center text-muted font-weight-light">
                <i class="mdi mdi-clock icon-sm mr-2"></i>
                <span>October 3rd, 2018</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 pr-1">
                <img src="../../assets/images/dashboard/img_1.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
                <img src="../../assets/images/dashboard/img_4.jpg" class="mw-100 w-100 rounded" alt="image">
              </div>
              <div class="col-6 pl-1">
                <img src="../../assets/images/dashboard/img_2.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
                <img src="../../assets/images/dashboard/img_3.jpg" class="mw-100 w-100 rounded" alt="image">
              </div>
            </div>
            <div class="d-flex mt-5 align-items-top">
              <img src="../../assets/images/faces/face3.jpg" class="img-sm rounded-circle mr-3" alt="image">
              <div class="mb-0 flex-grow">
                <h5 class="mr-2 mb-2">School Website - Authentication Module.</h5>
                <p class="mb-0 font-weight-light">It is a long established fact that a reader will be distracted by the readable content of a page.</p>
              </div>
              <div class="ml-auto">
                <i class="mdi mdi-heart-outline text-muted"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-md-7 stretch-card grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Project Status</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> # </th>
                    <th> Name </th>
                    <th> Due Date </th>
                    <th> Progress </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 1 </td>
                    <td> Herman Beck </td>
                    <td> May 15, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 2 </td>
                    <td> Messsy Adam </td>
                    <td> Jul 01, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-danger" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 3 </td>
                    <td> John Richards </td>
                    <td> Apr 12, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-warning" role="progressbar" style="width: 90%" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 4 </td>
                    <td> Peter Meggik </td>
                    <td> May 15, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-primary" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 5 </td>
                    <td> Edward </td>
                    <td> May 03, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-danger" role="progressbar" style="width: 35%" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 5 </td>
                    <td> Ronald </td>
                    <td> Jun 05, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-info" role="progressbar" style="width: 65%" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 stretch-card grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title text-white">Todo</h4>
            <todo-list />
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import axios from "axios"
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
import visitAndSalesStatitics from '../../components/charts/widgets/visitAndSalesStatitics'
import trafficSourceChart from '../../components/charts/widgets/trafficSourceChart'
// import todoList from '../../components/apps/todoList'
// import DatePicker from 'vue2-datepicker'; 


export default {
  name: 'dashboard',
  components: {
    // DatePicker,
    trafficSourceChart,
    visitAndSalesStatitics,
    // todoList
  },
  data() {
    return {
      time1: null,
      banner : [],
      totalWeekOrder : 0,
      totalWeekIncome : 0,
      weekOrder : 0,
      weekComplain : 0,
      allUnreadUser : [],
      allComplain : [],
      page: 1,
      limit: 10,
      counter: 0,
      intervalId: null,      
    };
  },
	methods: {
    numberWithCommas: (number) => packageGlobals.numberWithCommas(number),    
    startInterval() {
      this.intervalId = setInterval(this.incrementCounter, 3000);
    },
    stopInterval() {
      clearInterval(this.intervalId);
    },
    incrementCounter() {
      this.counter++;
      this.getAllUnreadUser()
      this.getAllComplain()
    },    
    getBanner: function (search, page, limit) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/banner?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        this.banner = response.data.result.data
        for (let index = 0; index < this.banner.length; index++) {
          this.banner[index].image = (this.banner[index].image != null) ? `${packageStore.mainApi}${this.banner[index].image}` : null
        }
			})			
    },
    getTotalWeekOrder: function () {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/analytic/week-order`,packageGlobals.configAxios)
			.then((response) => {
        this.totalWeekOrder = this.numberWithCommas(response.data.result.totalOrder)
        this.totalWeekIncome = this.numberWithCommas(response.data.result.totalIncome)
			})			
    },   
    getAllComplain : function (){
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/common/all-complain`,packageGlobals.configAxios)
			.then((response) => {
        this.allComplain = response.data.result
        this.weekComplain = this.allComplain.length
        if (this.allComplain.length != 0) {
          for (let index = 0; index < this.allComplain.length; index++) {
            this.allComplain[index].photo_profile = (this.allComplain[index].photo_profile != null) ? `${packageStore.mainApi}${this.allComplain[index].photo_profile}` : null
            switch (this.allComplain[index].status) {
              case 0:
                this.allComplain[index].status = 'New Complain'
                this.allComplain[index].colorBadge = 'badge-danger'
                break;
              case 1:
                this.allComplain[index].status = 'In Handle'
                this.allComplain[index].colorBadge = 'badge-warning'                
                break;
              case 3:
                this.allComplain[index].status = 'Completed'
                this.allComplain[index].colorBadge = 'badge-success'                
                break;                                
              default:
                break;
            }
          }          
        }        
			})			
    },
    getAllUnreadUser: function () {
      // this.allUnreadUser = []
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/chat-admin/all-unread-user`,packageGlobals.configAxios)
			.then((response) => {
        this.allUnreadUser = response.data.result
        if (this.allUnreadUser.length != 0) {
          for (let index = 0; index < this.allUnreadUser.length; index++) {
            this.allUnreadUser[index].photo_profile = (this.allUnreadUser[index].photo_profile != null) ? `${packageStore.mainApi}${this.allUnreadUser[index].photo_profile}` : null
          }          
        }
			})			
    },        
    toChatUser: function(item) {
      if (item != '-') {
        localStorage.setItem('chat-uuid-user',item.uuid_users); 
      }
      this.$router.push(`/dashboard/chat`)      
    },
    toHandlingComplainUser : function (item) {
      if (item != '-') {
        localStorage.setItem('handle-complain-uuid-user',item.uuid); 
      }
      this.$router.push(`/dashboard/handle-complain`)      
    }
  },
  created() {
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval();
  },    
	mounted() {
		this.getBanner('-', this.page, this.limit)
		this.getAllUnreadUser()
		this.getAllComplain()    
    this.getTotalWeekOrder()
	},  
}
</script>

<style scoped>
.count {
	display: block;
	/* width: auto; */
	width: 35px;  
	line-height: 26px;
	font-size: 14px;
	font-weight: 900;
	font-family: helvetica, sans-serif;
	color: #fff;
	text-decoration: none;
	text-align: center;
	border-radius: 100%;
	background-color: #00b050;
	border: 1px solid #bcbcbc;
	color: #fff;
	padding: 0px 7px 0 0;
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, sans-serif;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}
.count:before {
	float: left;
	margin-top: 4px;
	margin-left: 3px;
	display: block;
	width: auto;
	height: auto;
	content: ' ';
	-webkit-transform: skew(-35deg, 0);
	transform: skew(-35deg, 0);
	background-color: #00b050;
	border-left: 1px solid #aaa;
}
.count:after {
	position: relative;
	margin-top: -8px;
	margin-bottom: 4px;
	margin-left: -3px;
	margin-right: auto;
	display: block;
	width: 2px;
	height: 3px;
	content: ' ';
	-moz-transform: skew(35deg, 0);
	-ms-transform: skew(35deg, 0);
	-webkit-transform: skew(35deg, 0);
	transform: skew(35deg, 0);
	background-color: #efefef;
	border-left: 1px solid #aaa;
}

.list-selected-hover
{
  cursor: pointer;
}
.list-selected-hover:hover{
  background-color: bisque;
}
</style>